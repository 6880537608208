import { Box, HStack, VStack, useDisclosure, AlertTitle } from '@chakra-ui/react';
import { Accordion, Alert, Button, Tag } from 'Atoms';
import { SelectedMetric, useAssessmentReportingUnits } from 'containers/Esrs';
import {
  GetEsrsStandardHeaderQuery_,
  MaterialStandardMaterialRequirementsQuery_,
  useGetEsrsStandardHeaderQuery,
  useMaterialStandardMaterialRequirementsQuery,
} from 'models';
import { ContentHeader, ContentLayout, LearnMoreDrawer, MetricLearnMoreHeader } from 'Molecules';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { useMaterialStandardDataCollection } from './DataCollection.hooks';
import { DisclosureRequirementMetricsTable } from './DisclosureRequirementMetricsTable/DisclosureRequirementMetricsTable';
import { DataCollectionLevel, TableMetricData } from '.';
import { useCompanyType } from '../../../../utils/hooks';
import { WarningIcon } from '../../../../Tokens/Icons/Status';
import { useTranslation } from 'utils/translation';

const checkDataGatheringLevel = (requirements: any, dataCollectionLevel: string): boolean => {
  return requirements.some((requirement: any) =>
    requirement?.nonAggregatableMetrics?.some((metric: any) =>
      metric.materialMetrics?.some(
        (materialMetric: any) => materialMetric.dataCollection === dataCollectionLevel
      )
    )
  );
};

const WarningAlert = ({ header, message }: { header: string; message: string }) => {
  return (
    <Alert status="warning" closable={false} withIcon={false}>
      <HStack alignItems="start" height="16">
        <WarningIcon color="text.warning" />
      </HStack>
      <VStack w="100%" spacing="6px" ml={2} alignItems="start">
        <AlertTitle mb={0} fontSize="md" fontWeight="semibold">
          {header}
        </AlertTitle>
        <Typography variant="body" size="14px">
          {message}
        </Typography>
      </VStack>
    </Alert>
  );
};

const NonAggregatableMetricAlert = ({
  requirements,
}: {
  requirements: MaterialStandardMaterialRequirementsQuery_['DisclosureRequirement'];
}) => {
  const { isGroup } = useCompanyType();
  const { t } = useTranslation('esrs');

  const dataCollectionLevel = useMemo(
    () => (isGroup ? DataCollectionLevel.subsidiaries : DataCollectionLevel.reportingUnits),
    [isGroup]
  );
  const warningMessage = useMemo(
    () =>
      isGroup
        ? t('assessment.nonAggregatableAlert.groupMetricsMessage')
        : t('assessment.nonAggregatableAlert.companyMetricsMessage'),
    [isGroup]
  );

  const checkGatheringLevel = useMemo(
    () => checkDataGatheringLevel(requirements, dataCollectionLevel),
    [requirements, dataCollectionLevel]
  );

  if (checkGatheringLevel) {
    return (
      <WarningAlert header={t('assessment.nonAggregatableAlert.header')} message={warningMessage} />
    );
  }
  return null;
};

const StandardDataCollectionHeader = ({
  standard,
  isDataCollected,
  showConfigurationAlert,
}: {
  standard: GetEsrsStandardHeaderQuery_['esrsStandard'];
  isDataCollected: boolean;
  showConfigurationAlert: boolean;
}) => {
  return (
    <VStack spacing="16px">
      <HStack alignItems="flex-start" justifyContent="space-between" w="100%">
        <ContentHeader
          title={standard?.title ?? ''}
          subtitle={standard?.reference}
          props={{ padding: '0px' }}
        />
        <Box display="flex" justifyContent="flex-end" width="15%">
          <Tag variant={isDataCollected ? 'success' : 'warning'} borderRadius="4px" p="4px 10px">
            {isDataCollected ? 'Done' : 'To setup'}
          </Tag>
        </Box>
      </HStack>

      {showConfigurationAlert && (
        <Alert status="neutral" closable={false} withIcon={true}>
          <HStack w="100%" spacing="6px">
            <Typography variant="body" size="14px">
              Some metrics require the configuration of the breakdown
            </Typography>
          </HStack>
        </Alert>
      )}

      <Alert
        status="neutral"
        closable={false}
        title="Setup your data collection. You can change these settings later"
      />
    </VStack>
  );
};

export const StandardMetricsDataCollection = () => {
  const { standardRef = '', esrsAssessmentId = '' } = useParams();
  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>();
  const [showConfigurationAlert, setShowConfigurationAlert] = useState(false);
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const navigate = useNavigate();

  const {
    isDataCollected,
    updateDataCollectionStatus,
    companyStandardId,
    parentStandardId,
    parentEsrsAssessmentId,
    loading: dataCollectionStatusLoading,
  } = useMaterialStandardDataCollection(standardRef, esrsAssessmentId);

  const { data: standardData, loading: standardLoading } = useGetEsrsStandardHeaderQuery({
    variables: { reference: standardRef },
    skip: !standardRef,
  });
  const standard = useMemo(() => standardData?.esrsStandard, [standardData]);

  const { data: disclosureRequirementsData, loading } =
    useMaterialStandardMaterialRequirementsQuery({
      variables: {
        standardRef,
        companyStandardId,
        parentStandardId: parentStandardId || companyStandardId,
      },
      skip: !standardRef || !companyStandardId,
      fetchPolicy: 'network-only',
    });

  const requirements = useMemo(
    () => disclosureRequirementsData?.DisclosureRequirement ?? [],
    [disclosureRequirementsData]
  );

  const { reportingUnitAssessments: reportingUnits } =
    useAssessmentReportingUnits(esrsAssessmentId);
  const hasReportingUnits = useMemo(
    () => !!reportingUnits?.filter((ru) => ru.isCompanyLevel === false)?.length,
    [reportingUnits]
  );

  const isNonAggregatableMetricPresent = useMemo(
    () => requirements.some((requirement: any) => requirement.nonAggregatableMetrics.length > 0),
    [requirements]
  );

  const [selectedRows, setSelectedRows] = useState<TableMetricData[]>([]);

  return (
    <VStack
      bg="bg.defaultToDecorative"
      h="fit-content"
      alignItems="center"
      justifyContent="start"
      flexGrow="1"
    >
      <VStack w="100%" alignItems="start" flexGrow="1">
        <ContentLayout isLoading={loading} backButton={true} header={false}>
          <Box
            width="88%"
            bg="bg.default"
            border="1px solid"
            borderColor="border.decorative"
            borderRadius="8px"
            p="24px"
            margin="0 auto"
          >
            <ContentLayout
              variant="inline.nopad"
              isLoading={loading || standardLoading || dataCollectionStatusLoading}
              header={
                <StandardDataCollectionHeader
                  standard={standard}
                  isDataCollected={isDataCollected}
                  showConfigurationAlert={showConfigurationAlert}
                />
              }
            >
              {isNonAggregatableMetricPresent && (
                <Box width="100%" mt="16px">
                  <NonAggregatableMetricAlert requirements={requirements} />
                </Box>
              )}
              <VStack mt="32px" spacing="12px" width="100%" alignItems="start">
                {requirements.map((dr) => {
                  return (
                    <Accordion
                      variant="solid"
                      key={dr.reference}
                      lazyLoadContent={true}
                      marginBottom="0px"
                      items={[
                        {
                          label: dr.title,
                          reference: dr.reference,
                          content: (
                            <DisclosureRequirementMetricsTable
                              key={dr.reference}
                              disclosureRequirementRef={dr.reference}
                              disclosureRequirementType={dr.type}
                              setSelectedMetric={setSelectedMetric}
                              onDrawerOpen={onDrawerOpen}
                              setShowAlert={setShowConfigurationAlert}
                              parentEsrsAssessmentId={parentEsrsAssessmentId}
                              companyStandardId={companyStandardId}
                              parentStandardId={parentStandardId}
                              selectedRows={selectedRows}
                              setSelectedRows={setSelectedRows}
                              isStandardMandatory={!(standard?.isTopical ?? true)}
                              hasReportingUnits={hasReportingUnits}
                            />
                          ),
                        },
                      ]}
                    />
                  );
                })}

                <Button
                  variant="primary"
                  rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
                  onClick={() => {
                    updateDataCollectionStatus();
                    navigate(-1);
                  }}
                >
                  Done
                </Button>
              </VStack>
            </ContentLayout>
          </Box>

          <LearnMoreDrawer
            isOpen={isDrawerOpen}
            onClose={onDrawerClose}
            description={selectedMetric?.description}
            customHeader={
              <MetricLearnMoreHeader
                metricRef={selectedMetric?.reference ?? ''}
                tags={selectedMetric?.tags ?? []}
              />
            }
          />
        </ContentLayout>
      </VStack>
    </VStack>
  );
};
